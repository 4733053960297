import * as Sentry from "@sentry/browser";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { authUserSelector } from "../reducers/authReducer";

const SetSentryUser = () => {
  const user = useSelector(authUserSelector);

  useEffect(() => {
    if (!user) return;

    try {
      Sentry.configureScope(function (scope) {
        scope.setUser({ email: user.email, id: user.id, username: user.username });
      });
    } catch {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  return null;
};

export default SetSentryUser;
