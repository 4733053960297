import { HStack } from "@mailbrew/uikit";
import tinycolor from "tinycolor2";

const PaletteColorPicker = (props) => {
  const { selectedColor, palette = "backgrounds", onChange } = props;

  const selectedIndex = colorPickerPalette[palette].indexOf(selectedColor);

  return (
    <HStack px={0.5} style={{ width: "100%", marginTop: "0.3em" }} align="spaced" vAlign="center" gap={0}>
      {colorPickerPalette[palette].map((color, index) => {
        const active = selectedIndex === index;

        const parsedColor = `hsl(${tinycolor(color).toHsl().h}, ${tinycolor(color).toHsl().s * 100}%, 55%)`;

        const bg = editColor(parsedColor, { luminance: 0.6 });
        const border = editColor(parsedColor, { luminance: 0.4 });

        return (
          <div
            key={index}
            style={{
              width: "8px",
              height: "8px",
              borderRadius: "8px",
              background: bg,
              border: `2px solid ${active ? border : bg}`,
              cursor: "pointer",
              transform: `scale(${active ? 1.15 : 1})`,
              opacity: active ? 1 : 0.6,
            }}
            onClick={() => onChange(colorPickerPalette[palette][index])}
          />
        );
      })}
    </HStack>
  );
};

export const colorPickerPalette = {
  backgrounds: [
    "hsla(0, 0%, 50%, 0.06)",
    "hsla(10, 100%, 55%, 0.06)",
    "hsla(36, 100%, 55%, 0.06)",
    "hsla(72, 100%, 55%, 0.06)",
    "hsla(108, 100%, 55%, 0.06)",
    "hsla(144, 100%, 55%, 0.06)",
    "hsla(180, 100%, 55%, 0.06)",
    "hsla(216, 100%, 55%, 0.06)",
    "hsla(252, 100%, 55%, 0.06)",
    "hsla(288, 100%, 55%, 0.06)",
    "hsla(324, 100%, 55%, 0.06)",
  ],
  today: [
    "#F4344D",
    "#F46A25",
    "#F2A60D",
    "#B1DA0B",
    "#31CB0B",
    "#0FDBDB",
    "#3B86F7",
    "#7655F6",
    "#D142F5",
    "#F53DD6",
    "#4F5263",
  ],
};

export function editColor(color, props) {
  const { saturation, luminance, alpha } = props || {};

  const hsv = tinycolor(color).toHsl();

  if (saturation) {
    hsv.s = saturation;
  } else {
    if (hsv.s > 0.7) hsv.s = 0.7;
  }

  if (luminance) hsv.l = luminance;
  if (alpha) hsv.a = alpha;

  return tinycolor(hsv).toHslString();
}

export default PaletteColorPicker;
