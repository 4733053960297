import { notif } from "@mailbrew/uikit";
import { createSlice } from "@reduxjs/toolkit";
import { mutate } from "swr";
import apiErrMsg from "utils/apiErrMsg";
import api from "../dependencies/api";
import { setError } from "./appReducer";
import { setUser, updateMe } from "./authReducer";

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    loading: {},
  },
  reducers: {
    setLoading(state, action) {
      const { key, loading } = action.payload;
      state.loading[key] = loading;
    },
    resetLoading(state) {
      state.loading = {};
    },
  },
});

const { actions, reducer } = settingsSlice;
// eslint-disable-next-line no-empty-pattern
export const { resetLoading } = actions;
export default reducer;

export function updateUser(userData) {
  return async function (dispatch, getState, { api }) {
    const { first_name, last_name, email, timezone } = userData;

    dispatch(setLoading("profile", true));

    try {
      const res = await api.post("/update_user/", {
        email,
        first_name,
        last_name,
        timezone,
      });
      dispatch(setUser(res.data));
      notif.success("Profile updated");
    } catch (err) {
      notif.error(apiErrMsg(err, "Error updating your profile"));

      if (!err.response.data.detail) {
        const errors = err.response.data;
        for (const key in errors) {
          dispatch(setError(`update_profile:${key}`, err.response.data[key].join("\n")));
        }
      }
    } finally {
      dispatch(setLoading("profile", false));
    }
  };
}

export function setLoading(key, loading) {
  return function (dispatch) {
    dispatch(actions.setLoading({ key, loading }));
  };
}

export function removeTwitterAccount() {
  return async function (dispatch) {
    await api.post("connect/twitter/delete/");
    dispatch(updateMe());
    mutate("/user_accounts/");
  };
}
