import { useConfig } from "@mailbrew/uikit";
import Router from "next/router";
import React from "react";
import { useSelector } from "react-redux";
import urls from "urls";
import { isClient } from "utils/env";
import { authUserSelector } from "../reducers/authReducer";

/**
 * Can't access this page when logged out.
 */
export default function loggedInPage(Component) {
  function WrappedComponent(props) {
    const user = useSelector(authUserSelector);
    const config = useConfig();

    if (isClient) {
      if (user) return <Component {...props} />;
      else {
        Router.push(loggedOutRedirectURL());
        return null;
      }
    } else {
      return <div key="logged_out_page" style={{ background: config.colors.bg1 }} />;
    }
  }

  WrappedComponent.displayName = getDisplayName(Component);

  return WrappedComponent;
}

export function loggedOutRedirectURL() {
  const currentPathname = window.location.pathname;
  const redirectURL = currentPathname === "/" ? urls.logout() : urls.logout(currentPathname);
  return redirectURL;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}
