import { colorPickerPalette } from "components/editor/PaletteColorPicker";
import { v4 as uuidv4 } from "uuid";
import { apiBaseURL } from "./env";
import isBrowserLocale24h from "./isBrowserLocale24h";

// this is not in sources.js to avoid a circular import problem
export default function makeSourceTemplate(type) {
  switch (type) {
    case "weather":
      return {
        id: uuidv4(),
        type,
        location_key: null,
        location_name: "",
      };
    case "subreddit":
      return {
        id: uuidv4(),
        type,
        subreddit: "",
        num_posts: 4,
        mode: "auto",
        images_style: "full",
        post_body: "excerpt",
        style: "card",
      };
    case "rss_feed":
      return {
        id: uuidv4(),
        type,
        title: "",
        url: "",
        num_posts: 4,
        show_images: true,
        post_body: "excerpt",
      };
    case "twitter_user":
      return {
        id: uuidv4(),
        type,
        screen_name: "",
        max_tweets: 3,
        mode: "most_popular",
        include_rt: false,
        images_style: "full",
        show_title: false,
      };
    case "twitter_search":
      return {
        id: uuidv4(),
        type,
        title: "",
        query: "",
        ordering: "mixed",
        max_tweets: 20,
        images_style: "full",
        width: "ultra_wide",
        columns: 3,
      };
    case "twitter_list":
      return {
        id: uuidv4(),
        type,
        list_id: null,
        title: "",
        mode: "most_popular",
        max_tweets: 20,
        max_tweets_per_user: 2,
        include_rt: false,
        images_style: "full",
      };
    case "twitter_likes":
      return {
        id: uuidv4(),
        type,
        title: "",
        mode: "list", // For the future if we want to allow selecting the accounts to include directly in Mailbrew
        list_id: null,
        show: "tweets", // or: "links", "images"
        max_tweets: 20,
        max_links: 5,
        images_style: "full",
        shares_style: "full",
      };
    case "twitter_timeline":
      return {
        id: uuidv4(),
        type,
        title: "",
        mode: "most_popular",
        max_tweets: 15,
        max_links: 5,
        max_tweets_per_user: 1,
        show_retweets: false,
        images_style: "full",
      };
    case "twitter_top_links":
      return {
        id: uuidv4(),
        type,
        list_id: null,
        title: "",
        use_home_timeline: true,
        max_links: 6,
        min_shares_to_include: 1,
        images_style: "wide_thumb",
        shares_style: "full",
      };
    case "hacker_news":
      return {
        id: uuidv4(),
        type,
        title: "Hacker News",
        num_posts: 6,
        include: ["story", "show_hn", "ask_hn", "poll"],
        post_body: "hidden",
        columns: 2,
        style: "colorful",
        style_color: "hsla(24, 100%, 50%, 0.05)",
        width: "wide",
      };
    case "youtube_playlist":
      return {
        id: uuidv4(),
        type,
        playlist_id: null,
        title: "",
        max_videos: 4,
        columns: 2,
      };
    case "youtube_channel":
      return {
        id: uuidv4(),
        type,
        channel_url: "",
        title: "",
        max_videos: 4,
        columns: 2,
      };
    case "podcast":
      return {
        id: uuidv4(),
        type,
        title: "",
        podcasts: [],
        images_style: "small_thumb_left",
        columns: 2,
        post_body: "excerpt",
      };
    case "covid":
      return {
        id: uuidv4(),
        type,
        country: "WW",
        country_name: "World",
      };
    case "google_news":
      return {
        id: uuidv4(),
        type,
        mode: "query", // query / topic
        query: "",
        lang: "en",
        url: "",
        title: "",
        exact_match: false,
        language: "en",
        num_posts: 4,
      };
    case "exchange_rate":
      return {
        id: uuidv4(),
        type,
        base: "USD",
        targets: ["EUR"],
      };
    case "stocks":
      return {
        id: uuidv4(),
        type,
        display: "stocks_portfolio", // "stock" "stocks_portfolio" "portfolio"
        show_holding_value: false,
        stocks: [
          {
            symbol: "AAPL",
            security_name: "Apple, Inc.",
            logo: apiBaseURL + "/stock_logo/AAPL/",
            holding: null,
          },
        ],
      };
    case "crypto":
      return {
        id: uuidv4(),
        type,
        display: "asset_portfolio", // "asset" "asset_portfolio" "portfolio"
        show_holding_value: false,
        assets: [
          {
            symbol: "BTCUSD",
            name: "Bitcoin to USD",
            currency: "USD",
            holding: null,
          },
        ],
      };
    case "today":
      return {
        id: uuidv4(),
        type,
        style: "day",
        accent_color: "hsl(352, 90%, 58%)",
      };
    case "text":
      return {
        id: uuidv4(),
        type,
        text: "",
      };
    case "separator":
      return {
        id: uuidv4(),
        type,
      };
    case "inbox":
      return {
        id: uuidv4(),
        type,
        max_messages: 10,
        style: "card",
      };
    case "devto":
      return {
        id: uuidv4(),
        type,
        num_posts: 4,
      };
    case "product_hunt":
      return {
        id: uuidv4(),
        type,
        num_posts: 6,
        columns: 2,
      };
    case "betalist":
      return {
        id: uuidv4(),
        type,
        num_posts: 3,
      };
    case "read_later":
      return {
        id: uuidv4(),
        type,
      };
    case "readwise":
      return {
        id: uuidv4(),
        type,
        quote_color: colorPickerPalette.backgrounds[0],
        num_posts: 3,
      };
    case "calendar":
      return {
        id: uuidv4(),
        timeframe: "today",
        type,
        selected_calendars: {},
        title: "Calendar",
        hour_format: isBrowserLocale24h() ? "h24" : "am",
      };
    default:
      throw new Error(`unrecognized source type: ${type}`);
  }
}
