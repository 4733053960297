export function handleNetworkError(error) {
  if (!error.request || !error.request.status) {
    // alert("Looks like there is a problem with your connection. Please check it and try again.");
    return true;
  }

  if (error.request.status === 500) {
    alert(
      "Oops, something went wrong. \nWe were notified of this error and will soon work on fixing it. Email us at hello@mailbrew.com if you want to provide more details."
    );
    return true;
  }

  return false;
}
