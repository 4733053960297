import { configureStore, getDefaultMiddleware, combineReducers } from "@reduxjs/toolkit";
import authReducer from "reducers/authReducer";
import settingsReducer from "reducers/settingsReducer";
import newslettersReducer from "reducers/newslettersReducer";
import appReducer from "reducers/appReducer";
import onboardingReducer from "reducers/onboardingReducer";
import monetizationReducer from "reducers/monetizationReducer";
import api, { injectDispatch } from "dependencies/api";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import currentNewsletterSyncer from "middleware/currentNewsletterSyncer";
import { isClient } from "utils/env";

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["user", "signupMethod", "twitterSignupId"],
};

const onboardingPersistConfig = {
  key: "onboarding",
  storage,
  whitelist: [
    "selectedTopics",
    "userAddedFeeds",
    "selectedFeeds",
    "userAddedSubreddits",
    "selectedSubreddits",
    "twitterListId",
    "selectedMoreSources",
  ],
};

function clientPersistReducer(persistConfig, reducer) {
  if (isClient) {
    return persistReducer(persistConfig, reducer);
  } else {
    return reducer;
  }
}

// Create the store
const store = configureStore({
  reducer: combineReducers({
    app: appReducer,
    auth: clientPersistReducer(authPersistConfig, authReducer),
    onboarding: clientPersistReducer(onboardingPersistConfig, onboardingReducer),
    newsletters: newslettersReducer,
    monetization: monetizationReducer,
    settings: settingsReducer,
  }),
  middleware: getDefaultMiddleware({
    thunk: { extraArgument: { api } },
    serializableCheck: {
      ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
    },
  }).concat([currentNewsletterSyncer]),
});

export const persistor = isClient ? persistStore(store) : null;

// Inject dispatch into api
injectDispatch(store.dispatch);

export default store;
