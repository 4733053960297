import { useBrowserStorage } from "@mailbrew/uikit";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { isClient } from "utils/env";

const SessionStorageVisitedPages = () => {
  const router = useRouter();
  const [visitedPages, setVisitedPages] = useBrowserStorage(
    "mb:visited:pages",
    [],
    isClient ? window.sessionStorage : null
  );

  useEffect(() => {
    try {
      setVisitedPages([router.asPath, ...(visitedPages ?? []).slice(0, 50)]);
    } catch (err) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return null;
};

export default SessionStorageVisitedPages;
