import pick from "lodash.pick";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";

const QUERY_PARAMS_TO_SAVE = ["aff", "utm_source", "utm_campaign", "utm_medium", "coupon"];
const LOCAL_STORAGE_KEY = "signup_query_params";

/**
 * Track URL parameters for **unauthenticated** users in local storage
 * so when they signup we know where they come from and can credit affiliates.
 */
const SignupParametersTracker = () => {
  const user = useSelector(authUserSelector);
  const router = useRouter();

  // Save query params on location change (when logged out)
  useEffect(() => {
    if (user?.id) return;
    saveQueryParameters(router.query);
  }, [router.query, user?.id]);

  return null;
};

function saveQueryParameters(queryParams) {
  try {
    const savedParams = getSavedSignupParameters();
    const mergedParams = { ...savedParams, ...queryParams };
    const cleanedMergedParams = pick(mergedParams, QUERY_PARAMS_TO_SAVE);
    saveParameters(cleanedMergedParams);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log("Error saving query params", err);
  }
}

export function getSavedSignupParameters() {
  let saved = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!saved || saved.length === 0) return null;

  try {
    const parsed = JSON.parse(saved);
    return pick(parsed, QUERY_PARAMS_TO_SAVE);
  } catch {
    return null;
  }
}

function saveParameters(params) {
  try {
    const serialized = JSON.stringify(params);
    localStorage.setItem(LOCAL_STORAGE_KEY, serialized);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log("Error saving params to local storage.", err);
  }
}

export default SignupParametersTracker;
