import axios from "axios";
import { logout } from "reducers/authReducer";
import { apiBaseURL } from "utils/env";

const api = axios.create({
  baseURL: apiBaseURL,
  timeout: 30000,
  // necessary to set and send cookies https://flaviocopes.com/axios-credentials/
  withCredentials: true,
});

let dispatch = null;

export const injectDispatch = (value) => {
  dispatch = value;
};

api.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.code === "ECONNABORTED") {
      return;
    }

    return Promise.reject(error);
  }
);

export const apiSwrFetcher = async (query) => (await api.get(query)).data;
export const axiosSwrFetcher = async (query) => (await axios.get(query)).data;

export default api;
