import { syncNewsletter } from "../reducers/newslettersReducer";

const currentNewsletterSyncer = (store) => (next) => (action) => {
  const prevState = store.getState();
  next(action);
  const currentState = store.getState();

  const prevNewsletter = prevState.newsletters.currentNewsletter;
  const currentNewsletter = currentState.newsletters.currentNewsletter;

  const difference = prevNewsletter && currentNewsletter && prevNewsletter !== currentNewsletter;

  if (difference) {
    store.dispatch(syncNewsletter());
  }
};

export default currentNewsletterSyncer;
