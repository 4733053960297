export default function apiErrMsg(error, fallback = "Network error", extract = (err) => err.response?.data.detail) {
  if (!error.response) {
    return "Can't connect to the server.";
  }

  const statusCode = error.response.status;

  const msg = extract(error);
  return msg ?? `${fallback} (${statusCode})`;
}
