import { UIContextProvider, useEventListener } from "@mailbrew/uikit";
import KeyboardShortcutsManager from "components/KeyboardShortcutsManager";
import MixpanelInject from "components/MixpanelInject";
import PaywallStateProvider from "components/PaywallStateProvider";
import SEO from "components/SEO";
import SessionStorageVisitedPages from "components/SessionStorageVisitedPages";
import SetSentryUser from "components/SetSentryUser";
import SignupParametersTracker from "components/SignupParametersTracker";
import { apiSwrFetcher } from "dependencies/api";
import useIsMac from "hooks/useIsMac";
import Head from "next/head";
import { Fragment, useEffect } from "react";
import { hotjar } from "react-hotjar";
import { Provider } from "react-redux";
import { RecoilRoot } from "recoil";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "store";
import { SWRConfig } from "swr";
import debug from "utils/debug";
import { isClient, isDevelopment } from "utils/env";
import { baseConfig } from "../config";

function MyApp({ Component, pageProps }) {
  // Setting a dynamic manifest with different icon asset on Mac.
  useIsMac((isMac) => {
    if (isMac) {
      document.querySelector("#manifest").setAttribute("href", "/manifest-mac.json");
    } else {
      document.querySelector("#manifest").setAttribute("href", "/manifest.json");
    }
  });

  // Debug utilities
  useEffect(() => {
    if (!isDevelopment && "serviceWorker" in navigator) {
      navigator.serviceWorker.register("/sw.js");
    }

    if (isClient && isDevelopment) {
      debug.install();
    }
    hotjar.initialize(2377507, 6);
  }, []);

  useEventListener("beforeinstallprompt", (event) => {
    event.preventDefault();
    // @ts-ignore
    window.showPwaInstallButton = true;
    // @ts-ignore
    window.pwaInstallPrompt = event;
  });

  return (
    <>
      {/* why SEO is here and not in _document? */}
      {/* https://nextjs.org/docs/messages/no-document-viewport-meta */}
      {/* https://nextjs.org/docs/messages/no-document-title */}
      <SEO />
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>
      <Provider store={store}>
        <SSRAwarePersistGate persistor={persistor}>
          <UIContextProvider configOverrides={baseConfig} notifications>
            <SWRConfig
              value={{
                revalidateOnFocus: false,
                fetcher: apiSwrFetcher,
              }}
            >
              <RecoilRoot>
                <PaywallStateProvider>
                  <KeyboardShortcutsManager />
                  <MixpanelInject />
                  <SessionStorageVisitedPages />
                  <SetSentryUser />
                  <SignupParametersTracker />
                  <Component {...pageProps} />
                </PaywallStateProvider>
              </RecoilRoot>
            </SWRConfig>
          </UIContextProvider>
        </SSRAwarePersistGate>
      </Provider>
    </>
  );
}

const SSRAwarePersistGate = (props) => {
  return isClient ? <PersistGate {...props} /> : <Fragment>{props.children}</Fragment>;
};

export default MyApp;
