export const vercelBranch = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF;
export const vercelCommitSha = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA;
export const isClient = typeof window !== "undefined";
export const isProduction = process.env.NODE_ENV === "production";
export const apiEnv = vercelBranch === "dev" ? "staging" : process.env.NEXT_PUBLIC_API_ENV ?? "production";
export const isDevelopment = process.env.NODE_ENV === "development";
export const apiBaseURL = (() => {
  switch (apiEnv) {
    case "dev":
      return "http://localhost:8000";
    case "staging":
      return "https://staging.api.mailbrew.com";
    case "production":
    default:
      return "https://api.mailbrew.com";
  }
})();
export const stripeKey = (() => {
  switch (apiEnv) {
    case "dev":
    case "staging":
      return "pk_test_51HhXxlAbRe5zNFzPoUQfWGpC72QwPekoy10u9GoSFKfVGOabAgISaQCGvtfPMoNMbbejikplvktLfuIDRfgEQGPK00TQVfMdUl";
    case "production":
    default:
      return "pk_live_51HhXxlAbRe5zNFzPMvKmU4y2cIIuWAtaFdyI4Wp7mk4FclHse1fqUMjNUASW3IFIpBtWJ61yUMmHFLFzeRtgHk2y00GNAxVegV";
  }
})();
export const frontendURL = (() => {
  switch (apiEnv) {
    case "dev":
      return "http://localhost:3000";
    case "staging":
      return "https://staging.mailbrew.com";
    case "production":
    default:
      return "https://app.mailbrew.com";
  }
})();

export function envSelect(productionValue, stagingValue) {
  switch (apiEnv) {
    case "dev":
    case "staging":
      return stagingValue;
    case "production":
    default:
      return productionValue;
  }
}
