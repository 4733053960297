import { notif } from "@mailbrew/uikit";
import { createSlice } from "@reduxjs/toolkit";
import Router from "next/router";
import urls from "../urls";
import { setLoading } from "./appReducer";
import { setUser } from "./authReducer";
import { setCurrentNewsletter } from "./newslettersReducer";

const initialState = {
  selectedTopics: [],
  userAddedFeeds: [],
  selectedFeeds: [],
  userAddedSubreddits: [],
  selectedSubreddits: [],
  twitterListId: null,
  selectedMoreSources: [],
  schedule: null,
  didJustCompleteOnboarding: false,
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    resetOnboardingState(state) {
      for (const key in initialState) {
        state[key] = initialState[key];
      }
    },
    setSelectedTopics(state, action) {
      state.selectedTopics = action.payload;
    },
    setUserAddedFeeds(state, action) {
      state.userAddedFeeds = action.payload;
    },
    setSelectedFeeds(state, action) {
      state.selectedFeeds = action.payload;
    },
    setUserAddedSubreddits(state, action) {
      state.userAddedSubreddits = action.payload;
    },
    setSelectedSubreddits(state, action) {
      state.selectedSubreddits = action.payload;
    },
    setTwitterListId(state, action) {
      state.twitterListId = action.payload;
    },
    setSelectedMoreSources(state, action) {
      state.selectedMoreSources = action.payload;
    },
    setSchedule(state, action) {
      state.schedule = action.payload;
    },
    setDidJustCompleteOnboarding(state, action) {
      state.didJustCompleteOnboarding = action.payload;
    },
  },
});

const { actions, reducer } = onboardingSlice;
export const {
  resetOnboardingState,
  setSelectedTopics,
  setUserAddedFeeds,
  setSelectedFeeds,
  setUserAddedSubreddits,
  setSelectedSubreddits,
  setTwitterListId,
  setSelectedMoreSources,
  setSchedule,
  setDidJustCompleteOnboarding,
} = actions;
export default reducer;

export const selectedTopicsSelector = (state) => state.onboarding.selectedTopics;
export const userAddedFeedsSelector = (state) => state.onboarding.userAddedFeeds;
export const selectedFeedsSelector = (state) => state.onboarding.selectedFeeds;
export const userAddedSubredditsSelector = (state) => state.onboarding.userAddedSubreddits;
export const selectedSubredditsSelector = (state) => state.onboarding.selectedSubreddits;
export const twitterListIdSelector = (state) => state.onboarding.twitterListId;
export const scheduleSelector = (state) => state.onboarding.schedule;
export const selectedMoreSourcesSelector = (state) => state.onboarding.selectedMoreSources;
export const didJustCompleteOnboardingSelector = (state) => state.onboarding.didJustCompleteOnboarding;

export function completeOnboarding(typefully = false) {
  return async function (dispatch, getState, { api }) {
    dispatch(setLoading(true));

    try {
      const state = getState();

      const selectedTopics = selectedTopicsSelector(state);
      const rssFeeds = selectedFeedsSelector(state);
      const subreddits = selectedSubredditsSelector(state);
      const twitterListId = twitterListIdSelector(state);
      const schedule = scheduleSelector(state);
      const selectedMoreSources = selectedMoreSourcesSelector(state);

      const res = await api.post("/onboarding/complete/", {
        topics: selectedTopics,
        rss_feeds: rssFeeds,
        subreddits: subreddits,
        twitter_list_id: twitterListId,
        schedule: schedule,
        more_sources: selectedMoreSources,
        typefully,
      });

      dispatch(setDidJustCompleteOnboarding(true));
      dispatch(setUser(res.data.user));
      dispatch(setCurrentNewsletter(res.data.newsletter));
      Router.push(urls.editNewsletter(res.data.newsletter.id));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function resetOnboarding() {
  return async function (dispatch, getState, { api }) {
    try {
      const res = await api.post("/reset_onboarding/");
      const user = res.data;
      dispatch(setUser(user));
      notif.success("Onboarding Reset");
    } catch (err) {
      notif.error("Error resetting onboarding");
    }
  };
}
