/* eslint-disable no-console */
import { resetOnboarding } from "../reducers/onboardingReducer";
import store from "../store";

const debug = {
  install: function () {
    window.debug = {};

    window.debug.monetizationStatus = function () {
      console.log("status:", store.getState().auth.user.monetization.status);
      console.log(store.getState().auth.user.monetization);
    };

    window.debug.resetOnboarding = function () {
      store.dispatch(resetOnboarding());
    };

    window.debug.user = function () {
      return store.getState().auth.user;
    };

    window.debug.show = () => {
      localStorage.setItem("mb:show_debug_ui", true);
      window.location.reload();
    };

    window.debug.hide = () => {
      localStorage.removeItem("mb:show_debug_ui");
      window.location.reload();
    };
  },
};

export default debug;
