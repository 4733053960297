import useEventListener from "hooks/useEventListener";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";
import urls from "urls";

const KeyboardShortcutsManager = () => {
  const user = useSelector(authUserSelector);
  const router = useRouter();

  useEventListener("keyup", (e) => {
    if (!user) {
      return;
    }
    if (!isValidKeyboardShortcutEvent(e)) {
      return;
    }
    handleKeyPress(e.key);
  });

  function handleKeyPress(key) {
    if (key === "1") {
      router.push(urls.brews());
    }
    if (key === "2") {
      router.push(urls.inbox());
    }
    if (key === "3") {
      router.push(urls.saved());
    }
    if (key === "4") {
      router.push(urls.manageBrews());
    }
    if (key === "5") {
      router.push(urls.profile(user.username));
    }
    if (key === "j") {
      handleJKNavigation(1);
    }
    if (key === "k") {
      handleJKNavigation(-1);
    }
  }

  return null;
};

function handleJKNavigation(direction) {
  const focusedEl = document.activeElement;
  if (focusedEl?.dataset?.hotkeyIndex) {
    const activeIndex = parseInt(focusedEl.dataset.hotkeyIndex);
    const nextIndex = activeIndex + direction;
    const nextEl = document.querySelector(`[data-hotkey-index="${nextIndex}"]`);
    if (nextEl) {
      nextEl.focus();
    }
  } else {
    const firstEl = document.querySelector(`[data-hotkey-index="0"]`);
    if (firstEl) {
      firstEl.focus();
    }
  }
}

export function focusElementAtIndex(index) {
  const element = document.querySelector(`[data-hotkey-index="${index}"]`);
  if (element) {
    element.focus();
  }
}

export function isValidKeyboardShortcutEvent(e) {
  if (excludedTags.includes(e.target?.tagName)) {
    return false;
  }
  if (excludedPaths.find((path) => window.location.pathname.includes(path))) {
    return false;
  }
  return true;
}

export const excludedTags = ["INPUT", "TEXTAREA"];
export const excludedPaths = ["/edit/", "/upgrade", "/welcome"];

export default KeyboardShortcutsManager;
