import { notif } from "@mailbrew/uikit";
import { createSlice } from "@reduxjs/toolkit";
import { updateMe } from "./authReducer";

const monetizationSlice = createSlice({
  name: "monetization",
  initialState: {
    unsubscribing: false,
    resubscribing: false,
  },
  reducers: {
    setResubscribing: (state, { payload }) => {
      state.resubscribing = payload;
    },
    setUnsubscribing: (state, { payload }) => {
      state.unsubscribing = payload;
    },
  },
});

const { actions } = monetizationSlice;
const { reducer } = monetizationSlice;
export default reducer;

export function unsubscribe(reason, detail) {
  return async function (dispatch, getState, { api }) {
    dispatch(actions.setUnsubscribing(true));

    try {
      await api.post("/unsubscribe/", {
        reason,
        detail,
      });
    } catch (err) {
      notif.error(err.response?.data?.detail || "Error unsubscribing, please contact support.");
    } finally {
      dispatch(actions.setUnsubscribing(false));
    }

    dispatch(updateMe());
  };
}

export function reactivateSubscription() {
  return async function (dispatch, getState, { api }) {
    dispatch(actions.setResubscribing(true));

    try {
      await api.post("/reactivate_subscription/");

      notif.success("Success! You are now subscribed again.");
    } catch (err) {
      notif.error(err.response?.data?.detail || "Error re-subscribing, please try again or contact support.");
    } finally {
      dispatch(actions.setResubscribing(false));
    }

    dispatch(updateMe());
  };
}
