import { Checkbox, HStack, P2 } from "@mailbrew/uikit";
import { useMemo, useState } from "react";

const CheckboxRow = (props) => {
  const { children, onChange, initiallyChecked = false } = props;
  const [checked, setChecked] = useState(initiallyChecked);

  const id = useMemo(() => Math.random().toString(), []);

  return (
    <HStack vAlign="center" flexWrap="nowrap">
      <Checkbox
        id={id}
        checked={checked}
        onClick={() => {
          const newValue = !checked;
          setChecked(newValue);
          onChange && onChange(newValue);
        }}
      />
      <label htmlFor={id}>
        <P2 style={{ flex: 1, cursor: "pointer", fontSize: "13.5px" }}>{children}</P2>
      </label>
    </HStack>
  );
};

export default CheckboxRow;
