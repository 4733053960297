import { notif, NotifWithButton } from "@mailbrew/uikit";
import { createSlice } from "@reduxjs/toolkit";
import debounce from "just-debounce-it";
import Router from "next/router";
import notifApiError from "utils/notifApiError";
import urls from "../urls";
import { move } from "../utils/array";
import { handleNetworkError } from "../utils/networkErrors";
import makeSourceTemplate from "../utils/sourcesMakeTemplate";
import { resetError, resetMessage, setError, setLoading } from "./appReducer";

const newslettersSlice = createSlice({
  name: "newsletters",
  initialState: {
    syncing: false,
    sendingPreview: false,
    currentNewsletter: null,
    currentSyncedNewsletter: null,
  },
  reducers: {
    setSendingPreview(state, action) {
      state.sendingPreview = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setSyncing(state, action) {
      state.syncing = action.payload;
    },
    setCurrentNewsletter(state, action) {
      state.currentNewsletter = action.payload;
      state.currentSyncedNewsletter = action.payload;
    },
    setCurrentSyncedNewsletter(state, action) {
      state.currentSyncedNewsletter = action.payload;
    },
    setSourceBeingEditedIndex(state, action) {
      state.sourceBeingEditedIndex = action.payload;
      if (action.payload !== null) {
        state.sourceBeingEditedId = state.currentNewsletter?.sources[action.payload]?.id;
      } else {
        state.sourceBeingEditedId = null;
      }
    },
    updateCurrentNewsletterField(state, action) {
      const { key, value } = action.payload;
      state.currentNewsletter[key] = value;
    },
    setNewsletterField(state, action) {
      const { key, value } = action.payload;
      const n = state.currentNewsletter;
      n[key] = value;
    },
    addSource(state, action) {
      const { source, index } = action.payload;
      const n = state.currentNewsletter;

      if (!n.sources) {
        n.sources = [source];
      } else if (index >= 0) {
        n.sources.splice(index, 0, source);
      } else {
        n.sources.push(source);
      }
    },
    addSources(state, action) {
      const { sources, index } = action.payload;
      const n = state.currentNewsletter;

      if (!n.sources) {
        n.sources = sources;
      } else {
        if (typeof index !== "undefined") {
          n.sources.splice(index, 0, ...sources);
        } else {
          n.sources = n.sources.concat(sources);
        }
      }
    },
    setSourceField(state, action) {
      const { sourceIndex, key, value } = action.payload;
      const n = state.currentNewsletter;
      n.sources[sourceIndex][key] = value;
    },
    reorderSource(state, action) {
      const { from, to } = action.payload;
      const newsletter = state.currentNewsletter;
      newsletter.sources = move(newsletter.sources, from, to);
      state.currentNewsletter = newsletter;
    },
    removeSource(state, action) {
      const sourceIndex = action.payload;
      state.currentNewsletter.sources.splice(sourceIndex, 1);
    },
    setAccountSelectedCalendars(state, action) {
      const { sourceIndex, accountID, calendarIDs } = action.payload;
      const source = state.currentNewsletter.sources[sourceIndex];
      source.selected_calendars = {
        ...source.selected_calendars,
        [accountID]: calendarIDs,
      };
    },
  },
});

const { actions, reducer } = newslettersSlice;

export const brewSyncingSelector = (state) => state.newsletters.syncing;
export const currentNewsletterSelector = (state) => state.newsletters.currentNewsletter;
export const currentSyncedNewsletterSelector = (state) => state.newsletters.currentSyncedNewsletter;
export const sourceBeingEditedIndexSelector = (state) => state.newsletters.sourceBeingEditedIndex;
export const sourceBeingEditedIdSelector = (state) => state.newsletters.sourceBeingEditedId;

export const sendingPreviewSelector = (state) => state.newsletters.sendingPreview;

export const {
  setSendingPreview,
  setCurrentSyncedNewsletter,
  setSyncing,

  setCurrentNewsletter,
  updateCurrentNewsletterField,

  setSourceBeingEditedIndex,
  addSource,
  addSources,
  reorderSource,
  removeSource,
  setAccountSelectedCalendars,
} = actions;

export default reducer;

export function addAnotherSource(type) {
  return async function (dispatch, getState) {
    const newsletter = getState().newsletters.currentNewsletter;
    const sourceIndex = getState().newsletters.sourceBeingEditedIndex;
    await dispatch(addSourceOfType({ type: type, index: sourceIndex + 1 }));
    Router.push(urls.editNewsletterSource(newsletter.id, newsletter.sources ? sourceIndex + 1 : 0));
  };
}

export function fetchNewsletter(id) {
  return async function (dispatch, getState, { api }) {
    dispatch(setLoading(true));

    try {
      const res = await api.get(`/newsletters/${id}/`);
      dispatch(setCurrentNewsletter(res.data));
    } catch (err) {
      notifApiError(err, "Can't load this brew.");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function createNewsletter(title, callback) {
  return async function (dispatch, getState, { api }) {
    dispatch(setCurrentNewsletter(null));
    dispatch(setLoading(true));
    try {
      const res = await api.post(`/newsletters/`, { title });
      dispatch(setCurrentNewsletter(res.data));
      Router.push(urls.editNewsletter(res.data.id));
    } catch (err) {
      notifApiError(err, "Can't create a new brew");
      callback && callback(err);
    } finally {
      dispatch(setLoading(false));
      callback && callback();
    }
  };
}

export function deleteNewsletter(id) {
  return async function (dispatch, getState, { api }) {
    try {
      dispatch(setLoading(true));
      await api.delete(`/newsletters/${id}/`);
    } catch (err) {
      const handled = handleNetworkError(err);
      if (handled) return;
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function syncNewsletter(completion) {
  return (...args) => {
    _syncNewsletter(completion, ...args);
  };
}

const syncDelay = 500;

const _syncNewsletter = debounce(async (completion, dispatch, getState, { api }) => {
  const newsletter = getState().newsletters.currentNewsletter;

  dispatch(setSyncing(true));

  try {
    const res = await api.patch(`/newsletters/${newsletter.id}/`, newsletter);
    dispatch(resetError("sync"));
    dispatch(setCurrentSyncedNewsletter(res.data));
    // eslint-disable-next-line no-console
    console.log("✅ newsletter synced");
    completion?.();
  } catch (err) {
    notifApiError(err, "Error syncing your brew");
    const errMessage = err.response?.data.detail ?? "Error syncing your brew";
    dispatch(setError("sync", errMessage));
    // eslint-disable-next-line no-console
    console.log("❌ newsletter sync failed");
  } finally {
    dispatch(setSyncing(false));
  }
}, syncDelay);

export function sendPreview() {
  return async function (dispatch, getState, { api }) {
    const currentNewsletter = getState().newsletters.currentNewsletter;
    const newsletterId = currentNewsletter.id;

    if (!currentNewsletter.sources || currentNewsletter.sources.length === 0) {
      dispatch(setError("send_preview", "Add some sources below before sending a preview."));
      return;
    }

    const VIRTUAL_DELAY = 1000;

    try {
      dispatch(setSendingPreview(true));
      dispatch(resetError("send_preview"));
      dispatch(resetMessage("send_preview"));
      api.post(`/generate_and_send_test_issue/`, {
        newsletter_id: newsletterId,
      });
      setTimeout(() => {
        dispatch(setSendingPreview(false));
        notif.success("We've sent you a preview.");
      }, VIRTUAL_DELAY);
    } catch (err) {
      const handled = handleNetworkError(err);
      if (handled) return;
      setTimeout(() => {
        dispatch(setSendingPreview(false));
        notif.error(err.response?.data?.detail || "Ouch, there was a problem.");
      }, VIRTUAL_DELAY);
    }
  };
}

export function updateNewsletterPaused(id, paused) {
  return async function (dispatch, getState, { api }) {
    try {
      await api.patch(`/newsletters/${id}/`, { paused: paused });
    } catch (err) {
      const handled = handleNetworkError(err);
      if (handled) return;

      if (err.response.data.action === "upgrade") {
        dispatch(showUpgradeNotification(err.response.data.detail));
      } else {
        notif.error(err.response?.data?.detail || "Can't do this right now");
      }
    }
  };
}

export function copySourceToNewsletter(newsletterID, source) {
  return async function (dispatch, getState, { api }) {
    notif.loading("Copying to your brew");

    try {
      await api.post(`/copy_source_to_newsletter/${newsletterID}/`, { source });
      notif.dismiss();
      notif.success(
        <NotifWithButton
          msg="Copied"
          action="Go to brew"
          onClick={() => {
            Router.push(urls.editNewsletter(newsletterID));
          }}
        />
      );
    } catch (err) {
      if (handleNetworkError(err)) return;
      const msg = err.response.data.detail || "Could not copy the source to your brew";
      notif.error(msg);
    }
  };
}

export function updateSourceField(sourceIndex, key, value) {
  return function (dispatch) {
    dispatch(actions.setSourceField({ sourceIndex, key, value }));
  };
}

export function updateNewsletterField(key, value) {
  return function (dispatch) {
    dispatch(actions.setNewsletterField({ key, value }));
  };
}

export function showUpgradeNotification(message) {
  return async function (dispatch, getState) {
    notif.show(
      <NotifWithButton
        msg={message}
        action="Upgrade"
        actionIcon="starBold"
        onClick={() => {
          Router.push(urls.upgrade());
          notif.dismiss();
        }}
      />
    );
  };
}

export function addSourceOfType({ index, type }) {
  return function (dispatch, getState) {
    const source = makeSourceTemplate(type);
    dispatch(addSource({ index, source }));
  };
}
