import { createSlice } from "@reduxjs/toolkit";

/**
 * Use it for global app state (like loading and errors)
 */
const appSlice = createSlice({
  name: "app",
  initialState: {
    loading: false,
    errors: {},
    messages: {},
  },
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    _setError(state, action) {
      const { key, message } = action.payload;
      state.errors[key] = message;
    },
    _setMessage(state, action) {
      const { key, message } = action.payload;
      state.messages[key] = message;
    },
    resetError(state, action) {
      const key = action.payload;
      delete state.errors[key];
    },
    resetErrors(state, action) {
      const keys = action.payload;
      keys.forEach((key) => {
        delete state.errors[key];
      });
    },
    resetMessage(state, action) {
      const key = action.payload;
      delete state.errors[key];
    },
  },
});

const { actions, reducer } = appSlice;

export const { setLoading, resetError, resetErrors, resetMessage } = actions;

export function appErrorSelector(key) {
  return (state) => state.app.errors[key];
}

export const appLoadingSelector = (state) => state.app.loading;

export default reducer;

export function setMessage(key, message) {
  return function (dispatch, getState, { api }) {
    dispatch(actions._setMessage({ key, message }));
  };
}

export function setError(key, message) {
  return function (dispatch, getState, { api }) {
    dispatch(actions._setError({ key, message }));
  };
}
