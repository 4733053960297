import { notif, NotifWithButton } from "@mailbrew/uikit";
import Router from "next/router";
import urls from "urls";
import apiErrMsg from "./apiErrMsg";

export default function notifApiError(error, fallback) {
  const msg = apiErrMsg(error, fallback);
  const action = error?.response?.data?.action;

  if (action === "upgrade") {
    notif.error(
      <NotifWithButton
        msg={msg}
        action="Upgrade"
        onClick={async (t) => {
          notif.dismiss(t.id);
          Router.push(urls.upgrade());
        }}
      />
    );
  } else {
    if (action === "reconnect_twitter_account") {
      notif.error(
        <NotifWithButton
          msg={msg}
          action="Reconnect"
          onClick={async (t) => {
            notif.dismiss(t.id);
            Router.push(urls.settings_accounts());
          }}
        />
      );
    } else {
      notif.error(msg);
    }
  }
}
