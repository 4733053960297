// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || "https://08c4b5c523ca46bd8a6ceb6d462cd078@o124904.ingest.sentry.io/1850298",
  ignoreErrors: ["pktAnnotationHighlighter"],
});
