import produce from "immer";

/**
 * Returns a copy of the array moving the element at index from to the index to
 */
export function move(array, from, to) {
  const arrayCopy = [...array];

  if (from === to) {
    return arrayCopy;
  }

  // remove element at from
  const [removedElement] = arrayCopy.splice(from, 1);
  // add removedElement at to
  arrayCopy.splice(to, 0, removedElement);

  return arrayCopy;
}

export function remove(array, findElement) {
  const copy = Array.from(array);
  const index = copy.findIndex(findElement);
  if (index === -1) return copy;
  copy.splice(index, 1);
  return copy;
}

/**
 * Shuffles array in place.
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  let j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

export function updatePaginatedItem(pages, identifyItem, updateItem) {
  return produce(pages, (updatesPages) => {
    for (const page of updatesPages) {
      for (const item of page.results) {
        if (identifyItem(item)) {
          updateItem(item);
          return;
        }
      }
    }
  });
}

export function removePaginatedItem(pages, identifyItem) {
  return produce(pages, (updatedPages) => {
    for (const page of updatedPages) {
      const itemIndex = page.results.findIndex(identifyItem);
      if (itemIndex !== -1) {
        page.results.splice(itemIndex, 1);
        return;
      }
    }
  });
}

/**
 * Generates an array [start, ..., end - 1]
 * @param {Number} start the number from which the range should start (inclusive)
 * @param {Number} end the number at which the range should end (not inclusive)
 */
export function range(start, end) {
  if (start >= 0 && end === undefined) {
    return range(0, start);
  }

  if (start >= end) {
    return [];
  }

  const res = [];
  let i = start;
  while (i < end) {
    res.push(i);
    i += 1;
  }

  return res;
}

/**
 * Returns all the elements that are in `arr1` but not in `arr2`
 */
export function difference(arr1, arr2) {
  const set2 = new Set(arr2);

  const res = [];

  for (const el of arr1) {
    if (!set2.has(el)) {
      res.push(el);
    }
  }

  return res;
}

// groupBy("id", [{id: 1, key: value}]) ==> {1: {id: 1, key: value}}
export function groupBy(key, array) {
  const res = {};

  for (const el of array) {
    res[el[key]] = el;
  }

  return res;
}
