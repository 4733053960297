/*
 * Detects navigator locale 24h time preference
 * It works by checking whether hour output contains AM ('1 AM' or '01 h')
 * based on the user's preferred language
 */
export default function isBrowserLocale24h() {
  try {
    return !new Intl.DateTimeFormat(navigator.language, { hour: "numeric" }).format(0).match(/AM/);
  } catch {
    return false;
  }
}
