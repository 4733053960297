import { useDetectBrowser } from "@mailbrew/uikit";
import { useEffect } from "react";

const useIsMac = (handler) => {
  const { isMac } = useDetectBrowser();

  useEffect(() => {
    handler(isMac);
  }, [handler, isMac]);
};

export default useIsMac;
